import {Component, OnDestroy} from '@angular/core';
import {CsrfTokenService} from "./common/services/csrf-token.service";
import {DecoratorService} from "./common/services/decorator.service";
import {ConfirmationService} from "primeng/api";
import {NgEventBus} from "ng-event-bus";
import Pusher from "pusher-js";
import {AuthService} from "./common/services/auth.service";
import State from "pusher-js/types/src/core/http/state";
import {PusherService} from "./services/pusher.service";
import {TopLoaderService} from "./common/services/top-loader.service";
import {PUSHER_CONNECTED, PUSHER_LOGIN_EVENT, USER_LOGIN} from "./common/app-events";
import {SwPush} from '@angular/service-worker';
import {PusherEvent} from "pusher-js/types/src/core/connection/protocol/message-types";


@Component({
  selector: 'app-root',
  template: `
    <div class="indeterminate-progress-bar" *ngIf="topLoaderService.visible">
      <div class="indeterminate-progress-bar__progress"></div>
    </div>
    <div class="h-full max-w-none">
      <router-outlet></router-outlet>
    </div>
    <p-toast position="top-right"></p-toast>
    <p-confirmPopup title="Confirm"></p-confirmPopup>
  `,
  providers: [ConfirmationService],

})
export class AppComponent {
  title = 'primeasia-rooster';
  public pusherState ?: string;
  public previousState ?: string;
  private vapidKeys: string = 'BAoOd4H-9xhpBoKFJkvuNiTEjYZKctK-t5hrl3w1HLIjAi35v04cYEKTpBTS8b_DLtdC-wXIhDwg7InC98cLfgA';

  constructor(
    public csrfTokenService: CsrfTokenService,
    public decoratorService: DecoratorService,
    public authService: AuthService,
    public eventBus: NgEventBus,
    public pusherService: PusherService,
    public topLoaderService: TopLoaderService,
    public swPush: SwPush,
  ) {

    this.csrfTokenService.getToken().subscribe();

    if (this.authService.loggedIn()) {
      this.eventBus.cast(USER_LOGIN, this.authService.user);
      //this.eventBus.cast(PUSHER_CONNECTED);
      //this.pusherService.connectToChannel('presence-users');
      eventBus.on(PUSHER_CONNECTED).subscribe(res => {
        this.pusherService.connectToChannel('private-user.' + this.authService.user.id)
      })
    }

    // user logged in app


  }


}

